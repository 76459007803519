@import './variables';

html {
    margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}
body {
    background: white;
    margin: 0;
    padding: 0;
    font-family: $primary-font;
}

button {
    background-color: white;
    border: 2px solid #D6A4FF;
    padding: 1.5em 5em;
    border-radius: 40px;
}

p {
    font-size: 1em;
}