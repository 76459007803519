.story-component {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2em 0;
    justify-content: center;
    background: linear-gradient(to bottom, #D6A4FF, #F9F1FF);
    width: calc(100% + 60px);
    margin-left: -30px;

    p {
        max-width: 400px;
        line-height: 2;
        text-align: center;
    }

    button {
        background-color: white;
    }
}