.contact-div {
  padding: 2em 0;
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 30px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  h2 {
    color: #333;
    text-align: center;
  }

  .contact-form {
    display: flex;
    flex-direction: column;
    align-items: center;

    input,
    textarea {
      width: 70%;
      max-width: 400px;
      padding: 10px;
      margin: 5px 0 15px;
      border: 1px solid #ddd;
      border-radius: 15px;
      background-color: #fff;
    }

    textarea {
      resize: vertical;
    }
  }
}
