@import "../../variables";

.header-box {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  
  ::before {
    content: "";
    position: absolute;
    left: -30px;
    bottom: 0;    
    width: calc(100% + 60px);
    height: 1px;
    background-color: lightgrey;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    z-index: 0;
  }

  ::after {
    content: "";
    position: absolute;
    left: -30px;
    bottom: 1px;    
    width: calc(100% + 60px);
    height: 10px;
    background-color: white;
    z-index: 1;
  }

  .logo {
    width: 50px;
    height: 50px;
  }

  .btn-div {
    display: flex;
    gap: 1rem;

    button {
      border-radius: 10px;
      border: none;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
      padding: 0.5rem;
      color: $tertiary-text-color;
    }

    .connect-btn {
      align-items: center;
      border-radius: 40px;
      display: flex;
      align-items: center;
      padding: 0.5rem 1rem;

      img {
        align-self: center;
        margin-right: 0.5rem;
        margin-bottom: 0.1rem;
      }

      span {
        font-weight: 600;
        font-size: 1.2rem;
        text-align: center;
      }
    }

    .menu-btn {
      border: none;
    }

    .menu-btn img {
      width: 24px;
      height: 24px;
      transition: opacity 0.3s ease-in-out;
    }

    .menu-btn img.hide {
      opacity: 0;
    }

    .menu-btn img.show {
      opacity: 1;
    }
  }
}
.header {
  position: relative;
}

.header-nav {
  display: none;
}

.header-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdown-menu {
  position: fixed;
  top: 90px;
  left: 0;
  width: 100vw;
  height: calc(100vh - 90px);
  background: white;
  color: $secondary-text-color;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  opacity: 0;
  transform: translateY(
    -20px
  );
  transition: opacity 0.3s ease, transform 0.3s ease;
  pointer-events: none;
}

.dropdown-menu.show {
  opacity: 1;
  transform: translateY(0);
  pointer-events: auto;
}

.dropdown-menu.hide {
  opacity: 0;
  transform: translateY(
    -20px
  );
  pointer-events: none;
}

.dropdown-menu ul {
  list-style: none;
  height: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.dropdown-menu a {
  color: $secondary-text-color;
  text-decoration: none;
  font-size: 24px;
}

// Media query for tablet and mobile
@media (min-width: 830px) {

  .menu-btn, .dropdown-menu {
    display: none;
  }

  .header-nav {
    display: block;

    ul {
      list-style: none;
      display: flex;
      justify-content: space-between;
      gap: 5em;
      margin-right: 4em;
    }
  }
}