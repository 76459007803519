footer {
    margin-top: 3em;
    text-align: center;
}

@media (min-width: 1200px) {
    footer {
        display: flex;
        justify-content: space-between;
        text-align: center;
    }
}