.sponsors-component {
  background-color: #d9d9d9;
  padding: 2em 0;
  width: calc(100% + 60px);
  margin-left: -30px;
  text-align: center;

  h2 {
    background-image: linear-gradient(to right, #ffffff, #E4C662);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
