
.faq {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .faq h2 {
    margin-bottom: 20px;
    text-align: center;
    margin: 1em 0;
  }
  
  .faq-list {
    border-top: 1px solid #ddd;
  }
  
  .faq-item {
    border-bottom: 1px solid #ddd;
  }
  
  .faq-question {
    width: 100%;
    border: none;
    padding: 2em;
    text-align: left;
    font-size: 1.2em;
    cursor: pointer;
    outline: none;
    transition: background-color 0.3s ease;
  }
  
  .faq-answer {
    background-color: #fff;
    font-size: 1em;
    line-height: 1.8;
    
    p {
        margin: 0 1em;
    }
  }
  