.events {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  overflow-x: auto;
  gap: 16px;
}

.events-component h2 {
  width: 100%;
  text-align: center;
  margin: 1em 0;
}

.card {
  flex: 0 0 auto;
  width: 300px;
  height: 560px;
}

@media (min-width: 830px) {
  .events {
    display: grid;
    grid-template-columns: repeat(2, 332px);
    max-width: 694px;
    margin: 0 auto;
    overflow-x: hidden;
    gap: 20px;
    padding: 0 16px;
  }
}

@media (min-width: 1100px) {
  .events {
    grid-template-columns: repeat(3, 332px);
    max-width: 1036px;
  }
}

@media (min-width: 1500px) {
  .events {
    grid-template-columns: repeat(4, 332px);
    max-width: 1400px;
  }
}
