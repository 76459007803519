.card {
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    max-width: 100%;
    margin: 16px;
    transition: box-shadow 0.3s ease;
  }
  
  .card:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .card-img {
    width: 100%;
  }
  
  .card-content {
    padding: 1em;
  }
  
  .card h3 {
    margin: 0 0 16px;
    font-size: 1.5em;
    color: #333;
  }
  
  .card-text {
    margin: 0;
    font-size: 1em;
    color: #666;
  }