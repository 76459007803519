.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .popup {
    background-color: white;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    max-width: 400px;
    width: 100%;
  }
  
  .popup h2 {
    margin-bottom: 16px;
    font-size: 1.5em;
    color: #333;
  }
  
  .popup input[type="email"] {
    width: calc(100% - 20px);
    padding: 10px;
    margin: 16px 0;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1em;
    box-sizing: border-box;
    border-radius: 20px;
  }

  .popup input[type="email"]:focus {
    border-color: #D6A4FF;
    outline: none;
  }
  
  
  .popup button {
    padding: 10px 20px;
    background-color: #D6A4FF;
    color: white;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    font-size: 1em;
  }
  
  .popup .close-btn {
    margin-top: 10px;
    background-color: transparent;
    color: #666;
    cursor: pointer;
    font-size: 0.9em;
  }