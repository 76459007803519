.hero-component {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hero-component-content {
  margin-top: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 800px;

  /* img {
    width: 60%;
    margin: 3em;
  } */

  .img-container {
    width: 100%;
    height: auto;
    margin: 2rem 0 0rem 0;

    img {
        width: 100%;
        border-radius: 20px;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
    }
}

h1 {
  color: $secondary-text-color;
  font-size: 3rem;
  font-weight: 300;
}

    p {
      margin-top: 2rem;
      line-height: 1.5;
      margin-bottom: 0;
    }

  .btn-div {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: 4rem;
    margin-bottom: 4rem;

    button {
      cursor: pointer;
    }

    #event-btn {
      background-color: #d6a4ff;
    }
  }
}
