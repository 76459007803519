@import "./variables";

.App {
  padding: 20px 30px;
  background-color: white;
  width: calc(100% - 60px);
  border-radius: 15px;
  /* box-shadow: 0 0 5px rgba(0, 0, 0, 0.5); */

 /*  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 90px;
    left: 0;
    width: 100%;
    border-bottom: 1px solid rgb(214, 214, 214); 
    box-shadow: 0 2px 4px $primary-btn-color;
  } */

  h1, h2, h3 {
    color: $secondary-text-color;
    margin: 0;
    padding: 0;
  }

  h2 {
    font-size: 2em;

  }
}

@import "./components/Header/Header";
@import "./components/Hero/Hero";
@import "./components/Sponsors/Sponsors";
@import "./components/Story/Story";
@import "./components/Events/Events";
@import "./components/EventCard/EventCard";
@import "./components/Contact/Contact";
@import "./components/Footer/Footer";
@import "./components/Popup/Popup";
@import "./components/FAQ/FAQ";